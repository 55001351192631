<template>
	<div class="container">
		<div class="abstract">
			<img src="../../assets/imgs/home.png" alt="">
			<div class="cont">
				<p class="title">Explore and innovate  pursue excellence</p>
				
				<p class="subTitle"><span class="line"></span>开拓创新&emsp;追求卓越</p>
				<p class="text">推广物联网行业的普及、研学、发展、工厂落地和技术创新</p>
			</div>
		</div>
		
		<div class="about-us">
			<div class="text">
				<p class="title">关于我们</p>
				<div class="line"></div>
				<p class="subTitle">天乾地坤软件科技股份有限公司</p>
				<p class="content">承德市天乾地坤软件科技股份有限公司是一家以数字经济、城市信息化建设、行业数字化解决方案、为主要研究方向的企业。公司主营板块有：数字城市、智慧教体、综合能源、城市更新、数字乡村、全要素疫情防控、应急管理、数字金融、数字森林九大板块，涵盖智慧家庭解决方案、全要素疫情防控应急管理解决方案、智慧社区解决方案、数字化城市解决方案、智慧校园解决方案、全域智慧旅游解决方案等</p>
				<img class="seemore" src="../../assets/imgs/seeMore.png" alt="">
			</div>
			<img class="about-img" src="../../assets/imgs/aboutus.png" alt="">
		</div>
		
		<div class="business-scope">
			<div class="text">
				<p class="title">业务范围</p>
				<div class="line"></div>
				<p class="content">承德市天乾地坤软件科技股份有限公司是一家以数字经济、城市信息化建设、行业数字化解决方案、为主要研究方向的企业。</p>
			</div>
			<div class="business-scope-img">
				<div class="img-cont" v-for="item of businessArr" :key="item.id">
					<img :src="item.img" alt="">
					<p class="name">{{item.name}}</p>
				</div>
			</div>
		</div>
		
		<div class="news">
			<div class="text">
				<p class="title">新闻中心</p>
				<div class="line"></div>
			</div>
			
			<div class="news-cont">
				<div @click="handleUrlClick('https://baijiahao.baidu.com/s?id=1697088099224207393&wfr=spider&for=pc')" class="left">
					<img class="news-img" src="../../assets/imgs/about-news.png" alt="">
					<p class="title">涂鸦智能携手天乾地坤，助力家庭、商业与工业场景智慧化转型</p>
				</div>
				
				<div class="right">
					<div @click="handleUrlClick(item.url)" class="news-text" v-for="item of newsArr" :key="item.id">
						<p class="date">{{item.date}}</p>
						<div class="line"></div>
						<div class="content">
							<p class="title">{{item.title}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				businessArr: [
					{
						id: '1',
						name: '数字经济',
						img: require('@/assets/imgs/bs1.png')
					},
					{
						id: '2',
						name: '城市信息化建设',
						img: require('@/assets/imgs/bs2.png')
					},
					{
						id: '3',
						name: '行业数字化解决方案',
						img: require('@/assets/imgs/bs3.png')
					},
					{
						id: '4',
						name: '全要素疫情防控',
						img: require('@/assets/imgs/bs4.png')
					},
				],
				newsArr: [
					{
						id:'1',
						date: '04/10',
						title: '天乾地坤高端智能安全锁  ',
						url: 'https://baijiahao.baidu.com/s?id=1597353981920151466&wfr=spider&for=pc'
					},
					{
						id:'2',
						date: '01/27',
						title: '科技助力疫情防控“小门神”最美逆行',
						url: 'https://baijiahao.baidu.com/s?id=1690002956995037781&wfr=spider&for=pc'
					},
					{
						id:'3',
						date: '04/28',
						title: '人防变科技 科技帮大忙',
						url: 'https://baijiahao.baidu.com/s?id=1731302968590646359&wfr=spider&for=pc'
					},
				]
			}
		},
		
		methods:{
			handleUrlClick(val) {
				window.open(val)
				// location.href = val
			}
		}
	}
</script>

<style scoped>
.abstract {
	position: relative;
}

.abstract img {
	width: 100%;
}

.abstract .cont {
	position: absolute;
	top: 50%;
	right: 55px;
	transform: translate(0,-50%);
	text-align: right;
}
.abstract .cont .title {
	width: 776px;
	font-size: 48px;
	font-family: SourceHanSansCN-Light, SourceHanSansCN;
	font-weight: 300;
	color: rgba(255, 255, 255, .5);
	text-transform: uppercase;
}

.abstract .cont .subTitle {
	height: 48px;
	font-size: 48px;
	line-height: 48px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #FFFFFF;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}
.abstract .cont .subTitle  .line {
	display: inline-block;
	content: '';
	width: 27px;
	height: 8px;
	background-color: #fff;
	margin-right: 10px;
}
.abstract .cont .text {
	height: 22px;
	font-size: 22px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #FFFFFF;
	margin-top: 15px;
}

.about-us {
	padding: 84px 0 104px;
    display: flex;
	justify-content: center;
}
.about-us .about-img {
	width: 628px;
	height: 428px;
}

.seemore {
	width: 128px;
	margin-top: 77px;
	cursor: pointer;
}
 .text .title {
	height: 32px;
	font-size: 32px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
	
}
 .text .line {
	width: 39px;
	height: 8px;
	background: linear-gradient(130deg, #3582D5 0%, #184BAB 100%, #184BAB 100%);
	margin: 18px 0 52px;
}

 .text .content {
	width: 530px;
	font-size: 14px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #333333;
	line-height: 24px;
}
 .text .subTitle {
	height: 24px;
	font-size: 24px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
	margin-bottom: 18px;
}

.business-scope {
	padding: 0px 20% 0;
}

.business-scope .text .line {
	margin-bottom: 19px;
}

.business-scope .text .content {
	margin-bottom: 38px;
	width: 100%;
}

.business-scope-img {
	display: flex;
	align-items: center;
}

.img-cont {
	position: relative;
	height: 210px;
	width: 250px;
	color: #fff;
}



.img-cont img {
	width: 250px;
}

.img-cont:first-child {
	top: -2px;
	height: 213px;
}

.img-cont:nth-child(2) {
	color: #184BAB;
}

.img-cont .name {
	position: absolute;
	bottom: 18%;
	left: 50%;
	transform: translate(-50%,0);
	height: 18px;
	font-size: 18px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	white-space: nowrap;
}

.news {
	padding: 148px 20% 116px;

}

.news .text .line {
	margin-bottom: 34px;
}

.news-cont {
	display: flex;
	justify-content: space-between;
}

.news-img {
	width: 428px;
}

.news-cont .left, .right div {
	cursor: pointer;
}

.news-cont .left .title {
	width: 428px;
	height: 46px;
	font-size: 20px;
	font-family: SourceHanSansCN-Bold, SourceHanSansCN;
	font-weight: bold;
	color: #333333;
}

.news-cont .left .subTitle {
	width: 429px;
	height: 28px;
	font-size: 14px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #666666;
	margin-top: 16px;
}

.news-cont .right .news-text {
	display: flex;
	align-items: center;
	margin-bottom: 44px;
}

.news-cont .right .news-text:last-child {
	margin-bottom: 0;
}

.news-cont .right .news-text .date{
	height: 45px;
	font-size: 38px;
	font-family: DINAlternate-Bold, DINAlternate;
	font-weight: bold;
	color: #666666;
}

.news-cont .right .news-text:last-child .date {
	color: #184BAB;
}

.news-cont .right .news-text .line {
	width: 1px;
	height: 51px;
	background-color: #979797;
	margin: 0 30px;
}

.news-cont .right .news-text .content .title{
	font-size: 20px;
	font-family: SourceHanSansCN-Bold, SourceHanSansCN;
	font-weight: bold;
	color: #333333;
}

.news-cont .right .news-text .content .url {
	width: 350px;
	font-size: 14px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #666666;
	margin-top: 8px;
}

</style>